import * as PIXI from "pixi.js";


import Settings from '../settings.json';

import { WorldModel } from '../world/ubikampus';

// images
import UBIKAMPUS_BG from '../images/BG_Ubikampus.png';

import { Container } from 'pixi.js';

import { RoomsBlock, VerticalBlock } from './Map/RoomsBlock';
import { Door } from './Map/Door';
import { TBS2 } from './Map/Sensors/TBS2';
import { Sign } from './Map/Sign';

function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

function hideMouse() {
  const style = document.createElement('style');
  style.innerHTML = `
      * {
        cursor: none;
      }
    `;
  document.head.appendChild(style);
}


export class UbiMap extends Container {

  constructor(app) {
    super();

    this.app = app;

    this.rooms = [];
    this.desks = [];

    this.sensors = [];
    this.signs = [];

    this.backgroundContainer = null;
    this.mainContainer = null;
    this.iotContainer = null;
    this.infoContainer = null;

    this.scale = 1;

    this.setup();

    if(isTouchDevice()) {
      hideMouse();
    }
  }




  setup = () => {




    this.app.renderer.resolution = 1;
    this.app.renderer.backgroundColor = Settings.BACKGROUND_COLOR;

    this.mainContainer = new PIXI.Container({ resizeTo: window });

    this.backgroundContainer = new PIXI.Container({ resizeTo: window });
    this.backgroundContainer.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth * 10, window.innerWidth * 10);

    this.roomContainer = new PIXI.Container({ resizeTo: window });
    this.iotContainer = new PIXI.Container({ resizeTo: window });
    this.infoContainer = new PIXI.Container({ resizeTo: window });

    //////////////////////////////

    const map_texture = PIXI.Texture.from(UBIKAMPUS_BG);
    this.map_bg = new PIXI.Sprite(map_texture);

    this.map_bg.position = { x: 0, y: 0 };
    this.backgroundContainer.addChild(this.map_bg);

    this.mainContainer.addChild(this.backgroundContainer);
    this.mainContainer.addChild(this.roomContainer);
    this.mainContainer.addChild(this.iotContainer);
    this.mainContainer.addChild(this.infoContainer);
    this.addChild(this.mainContainer);

    
    this.backgroundContainer.pivot = {
      x: 0,
      y: 160
    };

    this.mainContainer.interactive = true;
    this.scale.set(1);

    this.drawRooms();
    this.focusOnSavedView('view1');
  };


  focusOnSavedView(viewId) {
    this.scale.set(1);
  }

  drawRooms() {

    try {

      console.log('Drawing rooms');
      const worldName = WorldModel.name;
      for (const index in WorldModel.model) {

        const item = WorldModel.model[index];

        if (item.type === "room-block-horizontal") {
          const roomBlock = new RoomsBlock(item.position);
          roomBlock.initializeWithDefinitions(this.roomContainer, item);
          this.rooms = this.rooms.concat(roomBlock.rooms);


          // Desks
        } else if (item.type === "desk-block-vertical") {

          const deskBlock = new VerticalBlock(item.position);
          deskBlock.initializeWithDefinitions(this.roomContainer, item);
          this.desks = this.desks.concat(deskBlock.drawables);

        } else if (item.type === "assets") {
          if (item.doors) {
            for (const index in item.doors) {
              const doorDetails = item.doors[index];
              const door = new Door(doorDetails);
              this.roomContainer.addChild(door);

            }
          }

          if (item.sensors) {
            for (const index in item.sensors) {
              const sensorDetails = item.sensors[index];
              const sensor = new TBS2(sensorDetails);
              this.iotContainer.addChild(sensor);
              sensor.showVOC();
              this.sensors.push(sensor);

            }
          }

          if (item.signs) {
            for (const index in item.signs) {
              const signDetails = item.signs[index];
              const sign = new Sign(signDetails);
              this.infoContainer.addChild(sign);
              this.signs.push(sign);

            }
          }
        }
      }


    } catch (error) {
      console.log('Error in loading the world model', error);
    }
  }


};







