import * as PIXI from "pixi.js";

export class TBS2 extends PIXI.Container {
    constructor(sensorDetails) {
        super();

        this.identity = sensorDetails.id;
        this.sensorDetails = sensorDetails;
        this.status = {};

        this.selectedSensor = 'voc';

        this.sensorPosition = sensorDetails.position;

        const gr = new PIXI.Graphics();
        gr.beginFill(0xFEB024);
        gr.drawCircle(35, 35, 35);
        gr.endFill();
        gr.pivot.set(gr.width / 2, gr.height / 2);
        gr.alpha = 0.25;

        gr.position = sensorDetails.position;
        this.addChild(gr);

        const initText = "N/A";
        this.statusMessage = new PIXI.Text(initText, { fontFamily: 'Arial', fontSize: 20, align: 'left' });
        this.addChild(this.statusMessage);
        this.statusUnit = new PIXI.Text("unit", { fontFamily: 'Arial', fontSize: 10, align: 'left' });
        this.addChild(this.statusUnit);
        this.setText(initText, "unit");

        this.enabled = sensorDetails.enabled;
        this.visible = false;
        this.updated = null;
    }

    setText(text, unit) {
        this.statusMessage.text = text;
        this.statusMessage.pivot.set(this.statusMessage.width / 2, (this.statusMessage.height / 2) - 4);
        this.statusMessage.position = this.sensorPosition;

        this.statusUnit.text = unit;
        this.statusUnit.pivot.set(this.statusUnit.width / 2, (this.statusUnit.height / 2) + 12);
        this.statusUnit.position = this.sensorPosition;

    }
    setSensorSelection(sensorName) {
        this.selectedSensor = sensorName;
        switch (sensorName) {
            case 'voc':
                this.showVOC();
                break;
            case 'co2':
                this.showCO2();
                break;
            case 'temperature':
                this.showTemperature();
                break;
            case 'humidity':
                this.showHumidity();
                break;
            case 'sound':
                this.showSound();
                break;
            case 'light':
                this.showAmbientLight();
                break;
            case 'pressure':
                this.showPressure();
                break;
            default:
                this.showVOC();
                break;
        }
    }

    setStatus(status) {
        //console.log('Setting status', status);
        this.status = status;
        this.updated = Date.now();
        this.visible = true;

        this.setSensorSelection(this.selectedSensor);
    }

    showVOC() {
        if (this.status.voc || this.status.voc === 0) {
            this.setText(this.status.voc, "VOC (ppb)");
        } else {
            this.setText("N/A", "unit");
        }
    }

    showCO2() {
        if (this.status.co2 || this.status.co2 === 0) {
            this.setText(this.status.co2, "CO2 (ppm)");
        } else {
            this.setText("N/A", "unit");
        }
    }

    showTemperature() {

        if (this.status.temperature || this.status.temperature === 0) {
            this.setText(this.status.temperature, "°C");
        } else {
            this.setText("N/A", "unit");
        }
    }

    showAmbientLight() {
        if (this.status['ambient-light'] || this.status['ambient-light'] === 0) {
            this.setText(this.status['ambient-light'], "lx");
        } else {
            this.setText("N/A", "unit");
        }
    }

    showHumidity() {
        if (this.status.humidity || this.status.humidity === 0) {
            this.setText(this.status.sound, "%");
        } else {
            this.setText("N/A", "unit");
        }
    }

    showSound() {
        if (this.status.sound || this.status.sound === 0) {
            this.setText(this.status.sound, "Vol (dB)");
        } else {
            this.setText("N/A", "unit");
        }
    }

    showPressure() {
        if (this.status.pressure || this.status.pressure === 0) {
            this.setText(this.status.pressure, "Pressure (mbar)");
        } else {
            this.setText("N/A", "unit");
        }
    }

}