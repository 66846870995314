import React from 'react'
import { connect } from 'react-redux'

//import RoboMap from './UbiMap'
import { PixiCanvas } from './PixiCanvas';
import { MapModeBtn } from './Buttons/MapModeBtn';
import { InfoBtn } from './Buttons/InfoBtn';
import { BottomMenu } from './Buttons/BottomMenu';
import { TopInfoMenu } from './Buttons/TopInfoMenu';
import { MousePositionLabel } from './Buttons/MousePositionLabel';
import { RoomInfoModal } from './RoomInfoModal';
import { UbiMapInfoModal } from './UbiMapInfoModal';

import NotificationUI from './NotificationUI';


class MainUI extends React.Component {

  componentDidMount() {

  }



  render() {

    return (
      <div>
        {/*<NotificationUI />*/}
       
       <UbiMapInfoModal />
       <RoomInfoModal />
       <PixiCanvas />
       <MapModeBtn />
       <InfoBtn />
       <BottomMenu />
       <TopInfoMenu />
       <MousePositionLabel />

      </div>
    )
  }
}



export default connect(null, {})(MainUI)
