import watch from 'redux-watch';

export default function createLayerVisibilityMediatorWithReduxWatch({ store, view, layerName }) {
    view.visible = store.getState().layerVisibility[layerName];
    const w = watch(store.getState, 'layerVisibility.' + layerName);
    let unsubscribe = store.subscribe(w((newVal, oldVal, objectPath) => {
        view.visible = newVal;
    }))

    // clear listeners
    return () => {
        unsubscribe();
    }
}
