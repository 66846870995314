export const ADD = 'ADD';
export const RESET = 'RESET';

export const MAP_MODE = 'MAP_MODE';
export const ROOM_OCCUPANCY_STATUS = 'ROOM_OCCUPANCY_STATUS';
export const ROOM_RESERVATION_STATUS = 'ROOM_RESERVATION_STATUS';

export const ROOM_OCCUPANCY_LEVEL = 'ROOM_OCCUPANCY_LEVEL';
export const ROOM_RESERVATION_LEVEL = 'ROOM_RESERVATION_LEVEL';

export const SENSOR_STATUS = 'SENSOR_STATUS';
export const SENSOR_SELECTION = 'SENSOR_SELECTION';

export const ROOM_LAYER_VISIBILITY = 'ROOM_LAYER_VISIBILITY';
export const IOT_LAYER_VISIBILITY = 'IOT_LAYER_VISIBILITY';
export const INFO_LAYER_VISIBILITY = 'INFO_LAYER_VISIBILITY';

export const MOUSE_POSITION = 'MOUSE_POSITION';

export const SHOW_ROOM_INFO_MODAL = 'SHOW_ROOM_INFO_MODAL';
export const CLOSE_ROOM_INFO_MODAL = 'CLOSE_ROOM_INFO_MODAL';

export const SHOW_MAIN_INFO_MODAL = 'SHOW_MAIN_INFO_MODAL';
export const CLOSE_MAIN_INFO_MODAL = 'CLOSE_MAIN_INFO_MODAL';


export const SHOW_MAIN_MENU = 'SHOW_MAIN_MENU';
export const CLOSE_MAIN_MENU = 'CLOSE_MAIN_MENU';