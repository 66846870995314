import * as types from './actionTypes';

export const add = () => ({ type: types.ADD });
export const reset = () => ({ type: types.RESET });

export const mapMode = () => ({ type: types.MAP_MODE });

export const roomOccupancyStatus = (roomIdentity, occupancyStatus) => ({ type: types.ROOM_OCCUPANCY_STATUS, 
    payload: {
        id: roomIdentity, 
        occupancyStatus: occupancyStatus
    }
});

export const roomOccupancyLevel = (roomIdentity, roomOccupancyLevel) => ({ type: types.ROOM_OCCUPANCY_LEVEL, 
    payload: {
        id: roomIdentity, 
        roomOccupancyLevel: roomOccupancyLevel
    }
});

export const roomReservationStatus = (roomIdentity, reservationStatus) => ({ type: types.ROOM_RESERVATION_STATUS, 
    payload: {
        id: roomIdentity, 
        reservationStatus: reservationStatus
    }
});

export const roomReservationLevel = (roomIdentity, roomReservationLevel) => ({ type: types.ROOM_RESERVATION_LEVEL, 
    payload: {
        id: roomIdentity, 
        roomReservationLevel: roomReservationLevel
    }
});

export const sensorStatus = (sensorId, status) => ({ type: types.SENSOR_STATUS, 
    payload: {
        id: sensorId, 
        status: status 
    }
});


export const roomLayerVisibility = () => ({ type: types.ROOM_LAYER_VISIBILITY });
export const iotLayerVisibility = () => ({ type: types.IOT_LAYER_VISIBILITY });
export const infoLayerVisibility = () => ({ type: types.INFO_LAYER_VISIBILITY });


export const mousePosition = (position) => ({ type: types.MOUSE_POSITION, 
    payload: position
});


export const sensorSelection = (sensorName) => ({ type: types.SENSOR_SELECTION, 
    payload: sensorName
});

export const showRoomInfoModalJep = (roomId) => ({ type: types.SHOW_ROOM_INFO_MODAL, 
    payload: {
        roomId: roomId
    }
});

export const showRoomInfoModal = (roomId) => ({ type: types.SHOW_ROOM_INFO_MODAL, 
    payload: {
        roomId: roomId
    }
});

export const closeRoomInfoModal = () => ({ type: types.CLOSE_ROOM_INFO_MODAL });


export const showMainInfoModal = () => ({ type: types.SHOW_MAIN_INFO_MODAL });
export const closeMainInfoModal = () => ({ type: types.CLOSE_MAIN_INFO_MODAL });


export const showMainMenu = () => ({ type: types.SHOW_MAIN_MENU });
export const closeMainMenu = () => ({ type: types.CLOSE_MAIN_MENU });