import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Image, Modal, List, Segment, Divider, Header, Grid } from 'semantic-ui-react';

import { closeMainInfoModal } from '../actions';

import BUILDING_FIG from '../images/physicum.png';
import MEETING_ROOM from '../images/meetingroom.jpg';

import STAFF_HALL_1 from '../world/images/staff-hall-1.jpg';
import SENSOR_FIG from '../images/experiment.jpg';

import MEETING_ROOM_1 from '../world/images/dart.jpg';

import DESK_1 from '../world/images/desk-1.jpg';
import STAFF_HALL_2 from '../world/images/staff-hall-3.jpg';
import STUDENT_HALL_1 from '../world/images/student-hall-1.jpg';
import KOTLIN from '../world/images/kotlin.jpg';

export function UbiMapInfoModal() {

  const dispatch = useDispatch();
  let mainInfo = useSelector(state => state.mainInfoForModal);

  const inlineStyle = {
    position: 'absolute !important',
    top: '100% !important',
    left: '10% !important'
  };

  const imgStyle = {
    paddingBottom: "10px"
  };

  return (
    <Modal
      style={inlineStyle}
      className='infoModal'
      closeIcon
      open={mainInfo.open}
      //open={true}
      onClose={() => {
        dispatch(closeMainInfoModal());
      }}
      closeOnDocumentClick={true}
      closeOnDimmerClick={true}
    >

      <Header as='h2' textAlign='center' color='yellow'>
        Ubikampus Map
      </Header>


      <Modal.Content scrolling>

        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Image src={BUILDING_FIG} style={imgStyle} size="medium" />
              <Image src={STAFF_HALL_1} style={imgStyle} size="medium" />
            </Grid.Column>
            <Grid.Column width={10}>
              <Header as='h3'>
                What is Ubikampus?
              </Header>
              <p>
                Ubikampus is on the university's digitalization roadmap. The goal is to bring ubiquitous computing to all campuses of the University of Helsinki.
              </p>
              <p>
                Ubikampus pilot space Ubi@Physicum is located on Kumpula campus library's 2nd floor. The best experimented ideas will be extended to all campuses of the University of Helsinki.
              </p>
              <p>
                Ubikampus is a living lab and smartspace concept. Ubikampus provides modern and flexible hybrid working and studying facilities for over 50 people. Ubikampus at Kumpula has two sides, staff side and student side.
              </p>

              <p>
                The key enabling technology of Ubikampus include:
              </p>
              <List bulleted>
                <List.Item>Open Ubikampus WiFi and wired network</List.Item>
                <List.Item>Open Ubikampus MQTT (with signature support)</List.Item>
                <List.Item>Ubikampus Map for visualizing the data</List.Item>
                <List.Item>Ubikampus Reservator – Ad hoc reservations sytem, integrated to the university's MS Office 365</List.Item>
              </List>


              <Header as='h3'>
                What is Ubikampus Map?
              </Header>

              <p>
                This Ubikampus Map system visualizes the data from the Open Data Bus of Ubikampus. The sensors and devices report their data and communicate via the Ubikampus Open MQTT Data Bus.
              </p>

            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Divider />
              <Header as='h2'>
                Meeting rooms and desks
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Image src={STAFF_HALL_2} size="medium" style={imgStyle} />
              <Image src={STUDENT_HALL_1} size="medium" style={imgStyle} />
            </Grid.Column>
            <Grid.Column width={12}>
              <p>
                The use of working facilities has changed. New ways of working are multi-location work and hybrid work. Ubikampus provides flexible working environment that also helps putting the space into more efficient use.
              </p>
              <List bulleted>
                <List.Item>
                <strong>Staff side:</strong>
                  <List.List>
                    <List.Item>3 ad hoc / reservable meeting rooms</List.Item>
                    <List.Item>3 ad hoc / reservable phone booths</List.Item>
                    <List.Item>37 flexi desks</List.Item>
                    <List.Item>6 allocated / reserved desks</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                <strong>Student side:</strong>
                  <List.List>
                    <List.Item>6 ad hoc / reservable meeting rooms</List.Item>
                    <List.Item>3 ad hoc / reservable phone booths</List.Item>
                    <List.Item>Plenty of desks and couches</List.Item>
                    <List.Item>Dedicated space for quiet work</List.Item>
                  </List.List>
                </List.Item>

              </List>

            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Image src={MEETING_ROOM_1} size="medium" />
            </Grid.Column>
            <Grid.Column width={4}>
              <Image src={MEETING_ROOM} size="medium" />
            </Grid.Column>
            <Grid.Column width={4}>
              <Image src={DESK_1} size="medium" />
            </Grid.Column>
            <Grid.Column width={4}>
              <Image src={KOTLIN} size="medium" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Divider />
              <Header as='h2'>
                Airquality and sensors
                <Header.Subheader>
                  If you have any comments or want to report bugs, contact:
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Image src={SENSOR_FIG} />
            </Grid.Column>
            <Grid.Column width={12}>
              <List bulleted>
                <List.Item><strong>VOC:</strong> Volatile organic compound are responsible for the odor of scents and perfumes as well as pollutants</List.Item>
                <List.Item><strong>CO2:</strong> Persons in buildings with high CO2 levels may complain of burning eyes, tiredness, and headaches. These symptoms can be caused by a combination of carbon dioxide and the many other pollutants that occur in a poorly ventilated space. Currently it is recommended that CO2 levels be maintained below 1,000 ppm</List.Item>
                <List.Item><strong>Temperature:</strong> (currently not very accurate)</List.Item>
                <List.Item><strong>Humidity:</strong> (currently not very accurate)</List.Item>
                <List.Item><strong>Ambient sound:</strong> Noise has a negative effect on the state of mind and health. Noise does not only refer to outside noise, but also to the dense background noise in rooms – the ambient sound.</List.Item>
                <List.Item><strong>Ambient light:</strong> Research has shown that some types of ambient light can increase alertness and affect energy levels. In workspaces blue and white lightning are good choices for making the employees more energetic.</List.Item>
                <List.Item><strong>Barometric pressure:</strong> Barometric pressure changes affect our bodies in a handful of ways. Some people may be more sensitive to weather changes than others, like people with migraine.</List.Item>
              </List>
            </Grid.Column>

          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Divider />
              <Header as='h2'>
                Contact information
                <Header.Subheader>
                  What to propose experiments, report a bug, or just give any comments? Contact:
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={10}>
              <List>
                <List.Item icon='user' content='Niko Mäkitalo' />
                <List.Item icon='marker' content='Department of Computer Science, Faculty of Science' />
                <List.Item
                  icon='mail'
                  content={<a href='#'>niko.makitalo@helsiki.fi</a>}
                />
                <List.Item
                  icon='linkify'
                  content={<a href='#'>https://map.ubikampus.net</a>}
                />
              </List>
            </Grid.Column>
          </Grid.Row>




        </Grid>





      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {
          dispatch(closeMainInfoModal());
        }} primary>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
};
