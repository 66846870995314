import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useStore } from 'react-redux';
import { UbiMap } from './UbiMap'
import { autoDetectRenderer } from 'pixi.js';
import { Viewport } from 'pixi-viewport';

import createMediators from './pixi/mediators/createMediators';

import * as PIXI from "pixi.js";

function render({ canvas, stage, stageWidth, stageHeight }) {
  const renderer = autoDetectRenderer({
    width: stageWidth,
    height: stageHeight,
    view: canvas,
    resolution: window.devicePixelRatio,
    transparent: false
  });
  function _render() {
    renderer.render(stage);
    requestAnimationFrame(_render);

  }
  _render();

  return renderer;
}



let pixiContainer = null;
function mountPixiContainer(element) {
  try {
    pixiContainer = element;
  } catch (error) {
    console.error(error);
  }
};

export function PixiCanvas() {
  const canvasRef = useRef(null);
  const store = useStore();

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {

  
/* 
// Before viewport
const app = new PIXI.Application({ resizeTo: window, transparent: false, resolution: window.devicePixelRatio });
pixiContainer.appendChild(app.view);
const ubimap = new UbiMap(app);
app.stage.addChild(ubimap);
*/


    const app = new PIXI.Application({ 
      resizeTo: window, 
      transparent: false, 
    //resolution: window.devicePixelRatio,
    //autoDensity: true,
    antialias: true,
    powerPreference: 'high-performance'
  
  
    });
        

    pixiContainer.appendChild(app.view);

    const viewport = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: window.innerWidth,
      worldHeight: window.innerHeight,

      interaction: app.renderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    });

    // add the viewport to the stage
    app.stage.addChild(viewport)

    const ubimap = new UbiMap(app);
    viewport.addChild(ubimap);

    // activate plugins
    viewport
      .drag()
      .pinch()
      .wheel()
      //.decelerate()
      .clampZoom({
        minScale: 0.5,
        maxScale: 1.5
      })

    function focusOn(viewObject) {
      const { position, zoomLevel } = viewObject;
      if(position && position.x && position.y && zoomLevel) {
        viewport.setZoom(zoomLevel);
        viewport.snap(position.x, position.y, {
          removeOnComplete: true
        });
      }
    }

    const savedViews = {
      view1: {
          position: {x: 950, y: 1100}, 
          zoomLevel: 0.75
      }
    }

    function focusOnSaved(viewId) {
      if(viewId in savedViews) {
        focusOn(savedViews[viewId]);
      }
    }

    const savedViewId = params.get('savedViewId');
    
    if(savedViewId) {
      console.log('Saved view id', params.get('savedViewId'));
      focusOnSaved(savedViewId);
    }



    const clear = createMediators({ store, stage: ubimap });
    return () => {
      //clear();
    }

  }, []);

  return (
    <>
      <div ref={mountPixiContainer} />
    </>
  );
}
