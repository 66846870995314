import { createStore, combineReducers } from 'redux'
//import notificationReducer from './reducers/notificationReducer'
import {
  roomsStatus, 
  layerVisibility, 
  mousePosition, 
  roomInfoForModal,
  mainInfoForModal,
  mainMenu,
  sensorStatus,
  sensorSelection
} from './reducers/index';

const reducer = combineReducers({
  //notification: notificationReducer,
  roomsStatusReducer: roomsStatus,
  layerVisibility,
  mousePosition,
  roomInfoForModal,
  mainInfoForModal,
  mainMenu,
  sensorStatus,
  sensorSelection
});

const store = createStore(
  reducer
)

export default store;