import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

import { useSelector, useDispatch } from 'react-redux';

import SETTINGS from '../../settings.json';

export function MousePositionLabel() {
  const dispatch = useDispatch();

  const mousePosition = useSelector(state => state.mousePosition);

  const mouseDetails = {
    position: "fixed",
    top: (window.innerWidth < 400 ? 10 : 10),
    right: (window.innerWidth < 400 ? 120 : 120),
    width: "120px",
    fontSize: 10
  };


  const mouseDetailsCoord = {
    width: "50px",
    position: "absolute",
    right: "2px"
  };

  if (SETTINGS.DEVELOPMENT) {
    return (
      <div>
        <Label style={mouseDetails} onClick={() => { }}>
          <Icon name='mouse pointer' color='blue' /><span>X: {mousePosition.x}</span><span style={mouseDetailsCoord}>Y: {mousePosition.y}</span>
        </Label>
      </div>
    )
  } else {
    return <div />
  }
}