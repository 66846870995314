export const WorldModel = {
    name: "Ubkampus",
    model: [
        // Staff side, block 1
        {
            type: "room-block-horizontal",
            height: 136,
            position: { x: 386, y: 859 },
            rooms: [
                {
                    type: "room",
                    id: "java",
                    parent: "staff-main",
                    details: {
                        name: "Java",
                        number: "G236",
                        title: "Coffee room",
                        description: "Staff coffee room and kitchen. The room should not be used for meetings and cannot be reserved.",
                        roomType: "coffee",
                        equipment: ["TV / monitor", "Coffee machine", "Electric kettle", "Fridge", "Microwave oven"],
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 620,
                                    y: 995
                                },
                                side: 'right'
                            },
                            {
                                position: {
                                    x: 435,
                                    y: 857
                                },
                                side: 'left'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 274, height: "parent" }
                },

                {
                    type: "room",
                    id: "ada",
                    parent: "staff-main",
                    details: {
                        name: "Ada",
                        number: "G237",
                        email: 'physicum.g237@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        equipment: ["TV / monitor", "Web camera", "Jabra"],
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 748,
                                    y: 995
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 121, height: "parent" }
                },

                {
                    type: "room",
                    id: "basic",
                    parent: "staff-main",
                    details: {
                        name: "Basic",
                        number: "G238",
                        email: 'physicum.g238@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        equipment: ["TV / monitor", "Web camera", "Jabra"],
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 840,
                                    y: 995
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 90, height: "parent" }
                },

                {
                    type: "room",
                    id: "cobol",
                    parent: "staff-main",
                    details: {
                        name: "Cobol",
                        number: "G239",
                        email: 'physicum.g239@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        equipment: ["TV / monitor", "Web camera", "Jabra"],
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 963,
                                    y: 995
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 121, height: "parent" }
                }
            ]
        },

        // Staff side, block 2
        {
            type: "room-block-horizontal",
            height: 61,
            position: { x: 1000, y: 934 },
            rooms: [
                {
                    type: "room",
                    id: "dart",
                    parent: "staff-main",
                    details: {
                        name: "Dart",
                        number: "G241",
                        email: 'physicum.g241@helsinki.fi',
                        title: "Phone booth",
                        roomType: "booth",
                        people: 2,
                        doors: [
                            {
                                position: {
                                    x: 1056,
                                    y: 995
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

                {
                    type: "room",
                    id: "erlang",
                    parent: "staff-main",
                    details: {
                        name: "Erlang",
                        number: "G243",
                        email: 'physicum.g243@helsinki.fi',
                        title: "Phone booth",
                        roomType: "booth",
                        people: 2,
                        doors: [
                            {
                                position: {
                                    x: 1149,
                                    y: 995
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

                {
                    type: "room",
                    id: "fortran",
                    parent: "staff-main",
                    details: {
                        name: "Fortran",
                        number: "G247",
                        email: 'physicum.g247@helsinki.fi',
                        title: "Phone booth",
                        roomType: "booth",
                        people: 2,
                        doors: [
                            {
                                position: {
                                    x: 1241,
                                    y: 995
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

            ]
        },
        // Student side, block 3
        {
            type: "room-block-horizontal",
            height: 114,
            position: { x: 412, y: 429 },
            rooms: [
                {
                    type: "room",
                    id: "kotlin",
                    parent: "staff-main",
                    details: {
                        name: "Kotlin",
                        description: "This room provides quite space fory studying. The room contains nine seats with view blocking walls. The room is isolated from the rest of Ubikampus with glass walls.",
                        number: "G225",
                        title: "Quiet studying space",
                        roomType: "quietspace",
                        people: 9,
                        doors: [
                            {
                                position: {
                                    x: 450,
                                    y: 542
                                },
                                side: 'left'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 247, height: "parent" }
                },

                {
                    type: "room",
                    id: "lisp",
                    parent: "staff-main",
                    details: {
                        name: "Lisp",
                        number: "G226",
                        email: 'physicum.g226@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 747,
                                    y: 542
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 121, height: "parent" }
                },

                {
                    type: "room",
                    id: "modula",
                    parent: "staff-main",
                    details: {
                        name: "Modula",
                        number: "G227",
                        email: 'physicum.g227@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 840,
                                    y: 542
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

                {
                    type: "room",
                    id: "neko",
                    parent: "staff-main",
                    details: {
                        name: "Neko",
                        number: "G228",
                        email: 'physicum.g228@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 948,
                                    y: 542
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 106, height: "parent" }
                },

                {
                    type: "room",
                    id: "octave",
                    parent: "staff-main",
                    details: {
                        name: "Octave",
                        number: "G229",
                        email: 'physicum.g229@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 1055,
                                    y: 542
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 106, height: "parent" }
                },

                {
                    type: "room",
                    id: "python",
                    parent: "staff-main",
                    details: {
                        name: "Python",
                        number: "G230",
                        email: 'physicum.g230@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 1149,
                                    y: 542
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

                {
                    type: "room",
                    id: "q",
                    parent: "staff-main",
                    details: {
                        name: "Q",
                        number: "G231",
                        email: 'physicum.g231@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 6,
                        doors: [
                            {
                                position: {
                                    x: 1242,
                                    y: 542
                                },
                                type: 'right'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                }
            ]
        },

        // Student side, block 4
        {
            type: "room-block-horizontal",
            height: 74,
            position: { x: 1000, y: 859 },
            rooms: [
                {
                    type: "room",
                    id: "idris",
                    parent: "staff-main",
                    details: {
                        name: "Idris",
                        number: "G240",
                        email: 'physicum.g240@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 3,
                        doors: [
                            {
                                position: {
                                    x: 1035,
                                    y: 857
                                },
                                side: 'left'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

                {
                    type: "room",
                    id: "haskell",
                    parent: "staff-main",
                    details: {
                        name: "Haskell",
                        number: "G242",
                        email: 'physicum.g242@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 3,
                        doors: [
                            {
                                position: {
                                    x: 1128,
                                    y: 857
                                },
                                side: 'left'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

                {
                    type: "room",
                    id: "go",
                    parent: "staff-main",
                    details: {
                        name: "Go",
                        number: "G244",
                        email: 'physicum.g244@helsinki.fi',
                        title: "Meeting room",
                        roomType: "meeting",
                        people: 3,
                        doors: [
                            {
                                position: {
                                    x: 1221,
                                    y: 857
                                },
                                side: 'left'
                            }
                        ]
                    },
                    enabled: true,
                    dimensions: { width: 91, height: "parent" }
                },

            ]
        },

        // Staff side, Desk block 1
        {
            type: "desk-block-vertical",
            position: { x: 1240, y: 1195 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-28",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-29",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-30",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Staff side, Desk block 2
        {
            type: "desk-block-vertical",
            position: { x: 1145, y: 1250 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-27",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-2",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Staff side, Desk block 3
        {
            type: "desk-block-vertical",
            position: { x: 1115, y: 1250 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-26",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-25",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Staff side, Desk block 4
        {
            type: "desk-block-vertical",
            position: { x: 1015, y: 1195 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-22",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-23",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-24",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Staff side, Desk block 5
        {
            type: "desk-block-vertical",
            position: { x: 985, y: 1195 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-21",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-20",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-19",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },
        // Staff side, Desk block 6
        {
            type: "desk-block-vertical",
            position: { x: 875, y: 1250 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-3",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-4",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },
        // Staff side, Desk block 7
        {
            type: "desk-block-vertical",
            position: { x: 905, y: 1250 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-5",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-6",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Staff side, Desk block 8
        {
            type: "desk-block-vertical",
            position: { x: 785, y: 1195 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-16",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-17",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-18",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Staff side, Desk block 9
        {
            type: "desk-block-vertical",
            position: { x: 755, y: 1195 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-15",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-14",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-13",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },
        // Staff side, Desk block 10
        {
            type: "desk-block-vertical",
            position: { x: 635, y: 1250 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-7",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-8",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },
        // Staff side, Desk block 11
        {
            type: "desk-block-vertical",
            position: { x: 665, y: 1250 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-9",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-10",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Staff side, Desk block 12
        {
            type: "desk-block-vertical",
            position: { x: 535, y: 1195 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-11",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-12",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-13",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },
        // Staff side, Desk block 13
        {
            type: "desk-block-vertical",
            position: { x: 505, y: 1195 },
            deskWidth: 50,
            deskHeight: 25,
            desks: [
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-14",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-15",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                },
                {
                    type: "Desk",
                    roomName: "hall_staff_side",
                    id: "hall_staff_side_desk-x-16",
                    parent: "staff-main",
                    enabled: true,
                    dimensions: { width: "block", height: "block" }
                }
            ]
        },

        // Other assets
        {
            type: "assets",
            doors: [
                {
                    type: "Door",
                    position: {
                        x: 1319,
                        y: 869
                    },
                    side: 'right'
                },
                {
                    type: "Door",
                    position: {
                        x: 1459,
                        y: 1308
                    },
                    side: 'right'
                }
            ],

            // Sensors
            sensors: [
                {
                    type: "tb-sense-2",
                    id: "hallway/tb-1",
                    position: {
                        x: 1253,
                        y: 1222
                    },
                    enabled: true
                },

                {
                    type: "tb-sense-2",
                    id: "hallway/tb-2",
                    position: {
                        x: 1026,
                        y: 1222
                    },
                    enabled: true
                },

                {
                    type: "tb-sense-2",
                    id: "hallway/tb-3",
                    position: {
                        x: 545,
                        y: 1222
                    },
                    enabled: true
                },

                {
                    type: "tb-sense-2",
                    id: "hallway/tb-4",
                    position: {
                        x: 800,
                        y: 1222
                    },
                    enabled: true
                }
            ],

            signs: [
                {
                    type: "sign",
                    style: "ubimap",
                    text: "Info",
                    position: {
                        x: 1390,
                        y: 1150
                    },
                    enabled: true
                },
                {
                    type: "sign",
                    style: "ubimap",
                    text: "Info",
                    position: {
                        x: 1515,
                        y: 235
                    },
                    enabled: true
                },
                {
                    type: "sign",
                    style: "area",
                    text: "Staff side\n entrance",
                    position: {
                        x: 1393,
                        y: 872
                    },
                    enabled: true
                },
                {
                    type: "sign",
                    style: "area",
                    text: "Staff side entrance",
                    position: {
                        x: 1405,
                        y: 1275
                    },
                    enabled: true
                },

                {
                    type: "sign",
                    style: "info",
                    text: "Coffee Room",
                    position: {
                        x: 545,
                        y: 930
                    },
                    enabled: true
                },

                {
                    type: "sign",
                    style: "info",
                    text: "Library downstairs",
                    position: {
                        x: 930,
                        y: 32
                    },
                    enabled: true
                },
                {
                    type: "sign",
                    style: "info",
                    text: "Library downstairs",
                    position: {
                        x: 930,
                        y: 700
                    },
                    enabled: true
                },

                {
                    type: "sign",
                    style: "info",
                    text: "Exactum",
                    position: {
                        x: 1555,
                        y: 1480
                    },
                    enabled: true
                },

                // Toilets
                {
                    type: "sign",
                    style: "info",
                    text: "WC",
                    position: {
                        x: 340,
                        y: 710
                    },
                    enabled: true
                },
                {
                    type: "sign",
                    style: "info",
                    text: "WC",
                    position: {
                        x: 1537,
                        y: 1040
                    },
                    enabled: true
                },
                {
                    type: "sign",
                    style: "info",
                    text: "WC",
                    position: {
                        x: 1477,
                        y: 940
                    },
                    enabled: true
                },
                {
                    type: "sign",
                    style: "info",
                    text: "WC",
                    position: {
                        x: 1477,
                        y: 1140
                    },
                    enabled: true
                }

            ]
        },



    ]
};










