import * as PIXI from "pixi.js";

export class Sign extends PIXI.Container {
    constructor(signDetails) {
        super();

        this.signDetails = signDetails;
        this.signPosition = signDetails.position;

        /*  
        // Hauska 0xFEB022
        const gr = new PIXI.Graphics();
        gr.beginFill(0xFEB022);
        gr.drawCircle(35, 35, 35);
        gr.endFill();
        gr.pivot.set(gr.width / 2, gr.height / 2);
        //gr.alpha = 0.25;
        */
        this.signText = null;
        const gr = new PIXI.Graphics();
        const initText = this.signDetails.text;
        
        if (this.signDetails.style === 'ubimap') {
            
            const margin = 6;
            this.signText = new PIXI.Text(initText, { fontFamily: 'Arial', fontSize: 20, align: 'left', fill: '#fff' });
            const signWidth = this.signText.width;
            const signHeight = this.signText.height;
            // Info: 0x1D6FC5
            gr.beginFill(0x1D6FC5);
            gr.drawCircle(25, 25, 25);
            gr.endFill();
            gr.pivot.set(gr.width / 2, gr.height / 2);
            gr.alpha = 0.85;

        } else if (this.signDetails.style === 'area') {
            const margin = 6;
            this.signText = new PIXI.Text(initText, { fontFamily: 'Arial', fontSize: 20, align: 'left', fill: '#fff' });
            const signWidth = this.signText.width;
            const signHeight = this.signText.height;
            // Info: 0x1D6FC5
            gr.beginFill(0x1D6FC5);
            gr.lineStyle(1.5, 0xffffff, 0.8);
            gr.drawRoundedRect(0, 0, signWidth + margin, signHeight + margin, 5);
            gr.endFill();
            gr.pivot.set(gr.width / 2, gr.height / 2);
            gr.alpha = 0.85;

        } else {            
            const margin = 6;
            this.signText = new PIXI.Text(initText, { fontFamily: 'Arial', fontSize: 20, align: 'left', fill: '#fff' });
            const signWidth = this.signText.width;
            const signHeight = this.signText.height;
            // Info: 0x1D6FC5
            gr.beginFill(0x1D6FC5);
            gr.lineStyle(1.5, 0xffffff, 0.8);
            gr.drawRoundedRect(0, 0, signWidth + margin, signHeight + margin, 5);
            gr.endFill();
            gr.pivot.set(gr.width / 2, gr.height / 2);
            gr.alpha = 0.85;

        }

        gr.position = signDetails.position;


        this.subText = new PIXI.Text("", { fontFamily: 'Arial', fontSize: 10, align: 'left' });
        this.setText(initText, "");

        this.addChild(gr);
        this.addChild(this.signText);
        this.addChild(this.subText);

        this.visible = true; //signDetails.enabled;

    }

    setText(text, unit) {
        this.signText.text = text;
        this.signText.pivot.set(this.signText.width / 2, (this.signText.height / 2) + 0);
        this.signText.position = this.signPosition;

        this.subText.text = unit;
        this.subText.pivot.set(this.subText.width / 2, (this.subText.height / 2) + 12);
        this.subText.position = this.signPosition;

    }

}