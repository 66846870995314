import createRoomMediatorWithReduxWatch from './createRoomMediatorWithReduxWatch';
import createLayerVisibilityMediatorWithReduxWatch from './createRoomLayerVisibilityMediatorWithReduxWatch';
import createMainContainerMediatorWithReduxWatch from './createMainContainerMediatorWithReduxWatch';
import createSensorMediatorWithReduxWatch from './createSensorMediatorWithReduxWatch';

export default function createMediators({ store, stage }) {
  

  const { rooms } = stage;  
  for( const i in rooms) {
    const room = rooms[i];
      const clearMediator1 = createRoomMediatorWithReduxWatch({ store, view: room });
  }

  const { desks } = stage;  
  for( const i in desks) {
    const desk = desks[i];
    const clearMediator1 = createRoomMediatorWithReduxWatch({ store, view: desk });
  }

  const { sensors } = stage;  
  for( const i in sensors) {
    const sensor = sensors[i];
      const clearMediator = createSensorMediatorWithReduxWatch({ store, view: sensor });
  }


  const { roomContainer, iotContainer, infoContainer } = stage;
  const clearMediator2_1 = createLayerVisibilityMediatorWithReduxWatch({ store, view: roomContainer, layerName: "room" });
  const clearMediator2_2 = createLayerVisibilityMediatorWithReduxWatch({ store, view: iotContainer, layerName: "iot" });
  const clearMediator2_3 = createLayerVisibilityMediatorWithReduxWatch({ store, view: infoContainer, layerName: "info" });

  const { mainContainer } = stage;
  const clearMediator3 = createMainContainerMediatorWithReduxWatch({ store, view: roomContainer });

  return () => {
    //clearMediator1();
    //clearMediator2();
    console.log('TODO: unsubscribe here..');
  };
  
}
