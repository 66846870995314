import watch from 'redux-watch';

import { mousePosition } from './../../../actions';


export default function createMainContainerMediatorWithReduxWatch({ store, view }) {
    const { dispatch, subscribe } = store;
    
/*    
    const defaultStatus = store.getState().mousePosition;
    console.log('Mouse mainContainer view defaultStatus', store.getState().mousePosition);

    // redux watch and update view after redux store state update.
    const w = watch(store.getState, 'mousePosition');
    let unsubscribe = store.subscribe(w((newVal, oldVal, objectPath) => {
        console.log('CHANGED: mousePosition');
        console.log('newVal', newVal);
        console.log('oldVal', oldVal);
        console.log('objectPath', objectPath);
        //view.setStatus(newVal.status);
    }));
*/

    // dispatch action 

    function onMouseMove(event) {
        try {
          this.data = event.data;
          let position = this.data.getLocalPosition(this);
          dispatch(mousePosition(position));
          
        } catch (error) {
          console.error(error);
        }
      }
  
    view.interactive = true;
    view.on('mousemove', onMouseMove);

    // clear listeners
    return () => {
        //view.off('click', onClick);
        //unsubscribe();
    }
}