
import * as PIXI from "pixi.js";

import { Colors } from '../MapConstants';

import { Door } from '../Door';

import Settings from '../../../settings.json';

export const Statuses = {
  FREE: 'FREE',
  RESERVED: 'RESERVED',
  NOT_RESERVED: 'NOT_RESERVED',
  OCCUPIED: 'OCCUPIED',
  NOT_OCCUPIED: 'NOT_OCCUPIED',
  UNKNOWN: 'UNKNOWN'
};

function flip(sprite) {
  sprite.anchor.y = 1;     /* 0 = top, 0.5 = center, 1 = bottom */
  sprite.scale.y *= -1;
  sprite.anchor.y = 0;
}

export class Reservable {
  constructor(parentContainer, type, identity, parent, dimensions, enabled) {

    this.identity = identity;
    this.type = type;

    this.parent = parent;
    this.reservableWidth = dimensions.width;
    this.reservableHeight = dimensions.height;
    this.container = new PIXI.Container();
    this.parentContainer = parentContainer;


    this.enabled = enabled;

    this.statuses = {
      reserved: false,
      occupied: false,
      free: true
    };


    this.position = {
      x: 300,
      y: 300
    }
  }


  draw() {

    if (!this.enabled) {
      return;
    }

    const position = this.position;
    const rect = new PIXI.Graphics();
    rect.lineStyle(2, Colors.ROOM_LINE);
    rect.beginFill(Colors.ROOM_BASE);
    rect.drawRect(position.x, position.y,
      this.reservableWidth, this.reservableHeight);
    this.container.addChild(rect);

    this.statusRectFree = new PIXI.Graphics();
    this.statusRectFree.beginFill(Colors.ROOM_STATUS_FREE);
    this.statusRectFree.drawRect(position.x, position.y,
      this.reservableWidth, this.reservableHeight);
    this.statusRectFree.visible = false;
    this.container.addChild(this.statusRectFree);

    this.statusRectFree.interactive = true;
    const name = this.name;
    this.statusRectFree.on('click', () => {
      console.log('Clicked on room:', name);
    });

    this.statusRectOccupied = new PIXI.Graphics();
    this.statusRectOccupied.beginFill(Colors.ROOM_STATUS_OCCIPIED);
    this.statusRectOccupied.drawRect(position.x, position.y,
      this.reservableWidth, this.reservableHeight);
    this.statusRectOccupied.visible = false;
    this.container.addChild(this.statusRectOccupied);

    this.statusRectReserved = new PIXI.Graphics();
    this.statusRectReserved.beginFill(Colors.ROOM_STATUS_RESERVED);
    this.statusRectReserved.drawRect(position.x, position.y,
      this.reservableWidth, this.reservableHeight);
    this.statusRectReserved.visible = false;
    this.container.addChild(this.statusRectReserved);
  }


  setPosition(position) {
    this.position = position;
  }


  setStatusFree() {
    this.statusRectFree.visible = true;
    this.statusRectOccupied.visible = false;
    this.statusRectReserved.visible = false;
  }


  setStatusOccupied() {
    this.statusRectFree.visible = false;
    this.statusRectOccupied.visible = true;
    this.statusRectReserved.visible = false;
  }


  setStatusReserved() {
    this.statusRectFree.visible = false;
    this.statusRectOccupied.visible = false;
    this.statusRectReserved.visible = true;
  }

  setStatusUnknown() {
    this.statusRectFree.visible = false;
    this.statusRectOccupied.visible = false;
    this.statusRectReserved.visible = false;
  }

  setStatus(status) {

    if (status === Statuses.OCCUPIED) {
      this.statusRectOccupied.visible = true;
      this.statuses.occupied = true;
      this.statuses.free = false;

    } else if (status === Statuses.NOT_OCCUPIED) {
      this.statusRectOccupied.visible = false;
      this.statuses.occupied = false;

    } else if (status === Statuses.RESERVED) {



      this.statusRectReserved.visible = true;
      this.statuses.reserved = true;
      this.statuses.free = false;

    } else if (status === Statuses.NOT_RESERVED) {
      this.statusRectReserved.visible = false;
      this.statuses.reserved = false;

    } else if (status === Statuses.FREE) {
      this.statusRectFree.visible = true;
      this.statuses.free = true;

    } else {
      this.statusRectFree.visible = false;
      this.statusRectOccupied.visible = false;
      this.statusRectReserved.visible = false;
      this.statuses.free = false;
      this.statuses.occupied = false;
      this.statuses.reserved = false;
    }
  }


}

export class Room extends Reservable {
  constructor(parentContainer, identity, parent, details, dimensions, enabled) {
    super(parentContainer, 'room', identity, parent, dimensions, enabled);

    this.name = details.name;
    this.number = details.number;
    this.doors = details.doors;
    this.details = details;
    this.details['id'] = identity;

    
  }

  on(eventName, handler) {
    this.container.interactive = true;

    const threshold = 10;
    function onMouseDown(_e) {
      _e.target.startTapX = _e.data.global.x;
      _e.target.startTapY = _e.data.global.y;
    }

    function onMouseUp(_e) {
      const oldX = _e.target.startTapX;
      const oldY = _e.target.startTapY;
      const newX = _e.data.global.x;
      const newY = _e.data.global.y;

      if (newX - threshold <= oldX && newX + threshold >= oldX && newY - threshold <= oldY && newY + threshold >= oldY) {
        // Do action
        handler();
      }

    }

    this.container
      .on('mousedown', onMouseDown)
      .on('touchstart', onMouseDown)
    // events for drag end
    //.on('mouseup', onMouseUp)
    //.on('mouseupoutside', onMouseUp)
    //.on('touchend', onMouseUp)
    //.on('touchendoutside', onMouseUp)

    /*
    function handlerWithMouseCheck() {
      if (!mouseDown && mouseDownStarted) {
        const nowTime = new Date();
        const seconds = (nowTime.getTime() - mouseDownStarted.getTime()) / 1000;

        if (seconds > Settings.PRESS_TIME) {
          handler();
        } else {
          //console.log('Too soon -> do nothing');
        }
      }
    }*/

    this.container.on(eventName, onMouseUp);

  }


  draw() {

    super.draw();

    const position = this.position;
    let text = new PIXI.Text(this.name, { fontFamily: 'Arial', fontSize: 14, fill: Colors.ROOM_NAME, align: 'left' });
    text.position = {
      x: position.x + this.reservableWidth * 0.2,
      y: position.y + this.reservableHeight / 3
    };
    this.container.addChild(text);

    let text2 = new PIXI.Text(this.number, { fontFamily: 'Arial', fontSize: 10, fill: Colors.ROOM_NAME, align: 'left' });
    text2.position = {
      x: text.position.x,
      y: text.position.y + 20
    };
    this.container.addChild(text2);

    if (this.doors) {
      for (let i in this.doors) {
        const doorDetails = this.doors[i];
        const door = new Door(doorDetails);
        this.container.addChild(door);
      }
    }

    this.parentContainer.addChild(this.container);

  }

}




export class Desk extends Reservable {

  constructor(parentContainer, identity, parent, details, dimensions, enabled) {

    super(parentContainer, 'desk', identity, parent, dimensions, enabled);

    this.name = details.name;
    this.roomName = details.roomName;
    this.details = details;

  }

  on(eventName, handler) {
    this.container.interactive = true;

    let mouseDownStarted;
    let mouseDown = false;
    function onDragStart() {
      mouseDown = true;
      mouseDownStarted = new Date();
    }

    function onDragEnd() {
      mouseDown = false;
    }

    this.container.on('mousedown', onDragStart)
      .on('touchstart', onDragStart)
      // events for drag end
      .on('mouseup', onDragEnd)
      .on('mouseupoutside', onDragEnd)
      .on('touchend', onDragEnd)
      .on('touchendoutside', onDragEnd)

    function handlerWithMouseCheck() {
      if (!mouseDown && mouseDownStarted) {
        const nowTime = new Date();
        const seconds = (nowTime.getTime() - mouseDownStarted.getTime()) / 1000;

        if (seconds > Settings.PRESS_TIME) {
          handler();
        } else {
          //console.log('Too soon -> do nothing');
        }
      }
    }

    this.container.on(eventName, handlerWithMouseCheck);

  }


  draw() {

    super.draw();

    const position = this.position;
    const showText = this.identity.replace(this.roomName + '_desk-','');
    let text = new PIXI.Text(showText, { fontFamily: 'Arial', fontSize: 14, fill: Colors.ROOM_NAME, align: 'left' });
    
    text.pivot.set(text.width / 2, text.height / 2);

    
    text.position = {
      x: position.x + this.reservableWidth / 2,
      y: position.y + this.reservableHeight / 2
    };

    text.angle = 90;
    this.container.addChild(text);

    this.parentContainer.addChild(this.container);

  }

  /*
  on(eventName, handler) {
      this.container.interactive = true;
  
      let mouseDownStarted;
      let mouseDown = false;
      function onDragStart() {
        mouseDown = true;
        mouseDownStarted = new Date();
      }
  
      function onDragEnd() {
        mouseDown = false;
      }
  
      this.container.on('mousedown', onDragStart)
        .on('touchstart', onDragStart)
        // events for drag end
        .on('mouseup', onDragEnd)
        .on('mouseupoutside', onDragEnd)
        .on('touchend', onDragEnd)
        .on('touchendoutside', onDragEnd)
  
      function handlerWithMouseCheck() {
        if (!mouseDown && mouseDownStarted) {
          const nowTime = new Date();
          const seconds = (nowTime.getTime() - mouseDownStarted.getTime()) / 1000;
  
          if (seconds > Settings.PRESS_TIME) {
            handler();
          } else {
            //console.log('Too soon -> do nothing');
          }
        }
      }
  
      this.container.on(eventName, handlerWithMouseCheck);
  
    }
  */

}