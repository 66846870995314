import React from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types";

import { Message } from 'semantic-ui-react'
import { types } from 'util';

class NotificationUI extends React.Component {

  handleDismiss = () => {
  }


  render() {
    if (this.props.notification.length === 0) {
      return null;
    }
    const style = {
      zIndex: 99,
      position: "fixed",
      top: 10,
      left: 130,
      right: 130,
      marginTop: 5,
      marginLeft: 50,
      marginRight: 50
    }

    return (
      <div style={style}>
        {this.context.store.getState().notification.map((noti) => {

          switch (noti.type) {

            case NOTIFICATION_TYPES.INFO:
              return <Message
                info
                icon='bell outline'
                onDismiss={this.handleDismiss}
                header={noti.type}
                content={noti.message}
                key={noti.id}
              />;
            case NOTIFICATION_TYPES.WARNING:
              return <Message
                negative
                icon='warning sign'
                onDismiss={this.handleDismiss}
                header={noti.type}
                content={noti.message}
                key={noti.id}
              />;
            case NOTIFICATION_TYPES.SUCCESS:
              return <Message
                positive
                icon='thumbs up outline'
                onDismiss={this.handleDismiss}
                header={noti.type}
                content={noti.message}
                key={noti.id}
              />;
            default:
              return null;
          }

        }
        )}
      </div>
    )
  }
}

NotificationUI.contextTypes = {
  store: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification
  }
}

export default connect(mapStateToProps)(NotificationUI);

export const NOTIFICATION_TYPES = {
  INFO: 'Notification',
  WARNING: 'Warning',
  SUCCESS: 'Success'
};

