import React, { useEffect, useState } from 'react';
import { Icon, Header, Card, List, Label } from 'semantic-ui-react';

import moment from 'moment';

import { Colors, getHEXString } from '../Map/MapConstants';

import { useDispatch, useSelector } from 'react-redux';
import { roomLayerVisibility, iotLayerVisibility, infoLayerVisibility, 
    showMainMenu, closeMainMenu, sensorSelection } from './../../actions';

function isSmallScreen() {
    return window.innerWidth < 500;
}

const AreasStatusColors = () => {

    const statusDim = 20;
    const roomStatusStyleFree = {
        height: statusDim,
        width: statusDim,
        backgroundColor: getHEXString(Colors.AREA_STAFF),
        borderRadius: '5%',
        display: 'inline-block'
    };

    const roomStatusStyleOccupied = {
        height: statusDim,
        width: statusDim,
        backgroundColor: getHEXString(Colors.AREA_STUDENT),
        borderRadius: '5%',
        display: 'inline-block'
    };

    const roomStatusStyleReserved = {
        height: statusDim,
        width: statusDim,
        backgroundColor: '#E9AA43',
        borderRadius: '5%',
        display: 'inline-block'
    };

    const roomStatusStyleUnknown = {
        height: statusDim,
        width: statusDim,
        backgroundColor: '#DFDFDF',
        borderRadius: '5%',
        display: 'inline-block'
    };

    const textStyle = {
        position: "relative",
        top: -4,
        left: 4,
        marginRight: 25

    };

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td><span style={roomStatusStyleFree}></span><span style={textStyle}>Staff side</span></td>
                        <td><span style={roomStatusStyleOccupied}></span><span style={textStyle}>Student side</span></td>
                    </tr>
                </tbody>
            </table>
        </div>);
}

const ReservableStatusColors = () => {

    const statusDim = 20;
    const roomStatusStyleFree = {
        height: statusDim,
        width: statusDim,
        backgroundColor: getHEXString(Colors.ROOM_STATUS_FREE),
        borderRadius: '5%',
        display: 'inline-block'
    };

    const roomStatusStyleOccupied = {
        height: statusDim,
        width: statusDim,
        backgroundColor: getHEXString(Colors.ROOM_STATUS_OCCIPIED),
        borderRadius: '5%',
        display: 'inline-block'
    };

    const roomStatusStyleReserved = {
        height: statusDim,
        width: statusDim,
        backgroundColor: getHEXString(Colors.ROOM_STATUS_RESERVED),
        borderRadius: '5%',
        display: 'inline-block'
    };

    const roomStatusStyleUnknown = {
        height: statusDim,
        width: statusDim,
        backgroundColor: getHEXString(Colors.ROOM_BASE),
        borderRadius: '5%',
        display: 'inline-block'
    };

    const textStyle = {
        position: "relative",
        top: -4,
        left: 4,
        marginRight: 25

    };

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td><span style={roomStatusStyleFree}></span><span style={textStyle}>Free</span></td>
                        <td><span style={roomStatusStyleOccupied}></span><span style={textStyle}>Occupied</span></td>
                    </tr>
                    <tr>
                        <td><span style={roomStatusStyleReserved}></span><span style={textStyle}>Reserved</span></td>
                        <td><span style={roomStatusStyleUnknown}></span><span style={textStyle}>Uknown</span></td>
                    </tr>
                </tbody>
            </table>
        </div>);
}

  
  const AirQualityLayerLabel = () => 
  {
    const dispatch = useDispatch();
    const layerVisibility = useSelector(state => state.layerVisibility);

    const lableStyle = {
        width: '125px',
        textAlign: 'center',
        marginBottom: '5px'
    };

    return (
    <div>
        <div>
      <Label as='a' style={lableStyle} color={'blue'} onClick={()=> {
        dispatch(sensorSelection('voc'));
      }}>
        VOC
      </Label>
      <Label as='a' style={lableStyle} color={'blue'} onClick={()=> {
        dispatch(sensorSelection('co2'));
      }}>
        CO<sub>2</sub>
      </Label>
      </div>
      <div>
      <Label as='a' style={lableStyle} color={'blue'} onClick={()=> {
        dispatch(sensorSelection('temperature'));
      }}>
        Temperature
      </Label>
      <Label as='a' style={lableStyle} color={'blue'} onClick={()=> {
        dispatch(sensorSelection('humidity'));
      }}>
        Humidity
      </Label>
      </div>
      <div>
      <Label as='a' style={lableStyle} color={'blue'} onClick={()=> {
        dispatch(sensorSelection('sound'));
      }}>
        Ambient Sound
      </Label>
      <Label as='a' style={lableStyle} color={'blue'} onClick={()=> {
        dispatch(sensorSelection('light'));
      }}>
        Ambient Light
      </Label>
      </div>
      <div>
      <Label as='a' style={lableStyle} color={'blue'} onClick={()=> {
        dispatch(sensorSelection('pressure'));
      }}>
        Barometric Pressure
      </Label>
      <Label as='a' style={lableStyle} color={'grey'} onClick={()=> {
        dispatch(iotLayerVisibility());
      }}>
        {layerVisibility.iot ? 'Hide' : 'Show'}<br/> Layer
      </Label>
      </div>
      </div>
  )
}

  
const UbiMapLayerCardsGroups = () => {

    const dispatch = useDispatch();
    const mainMenu = useSelector(state => state.mainMenu);

    const iconStyle = {
        float: 'right'
    };

    const scrollmenuStyle = {
        overflow: "auto",
        //whiteSpace: "nowrap"
        height: 230
    };

    const scrollmenuItemStyle = {
        //display: "inline-block",
        width: 1600,
        height: 220,
        padding: 10,
        textDecoration: "none"
    };

    
    return (
        <div style={scrollmenuStyle} className={mainMenu.open ? "hide-scrollbar menu-wrapper is-nav-open" : "hide-scrollbar menu-wrapper"}>
            <div className='nav'>

            <Card.Group style={scrollmenuItemStyle}>

                <Card onClick={() => {
                    console.log('TODO');
                }}>
                    <Card.Content>
                        <Icon name='map outline' size='big' style={iconStyle} color='blue' />
                        <Card.Header>Areas and Zones</Card.Header>
                        <Card.Meta>Areas and Zones</Card.Meta>
                        <Card.Description>
                            Show meeting rooms and their reservation status.
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <AreasStatusColors />
                    </Card.Content>
                </Card>

                <Card onClick={() => {
                    dispatch(roomLayerVisibility());
                }}>
                    <Card.Content>
                        <Icon name='comments outline' size='big' style={iconStyle} color='blue' />
                        <Card.Header>Meeting Rooms</Card.Header>
                        <Card.Meta>Meeting rooms real time status</Card.Meta>
                        <Card.Description>
                            Show meeting rooms and their reservation status.
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <ReservableStatusColors />
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content>
                        <Icon name='laptop' size='big' style={iconStyle} color='blue' />
                        <Card.Header>Desks</Card.Header>
                        <Card.Meta>Flexi desks</Card.Meta>
                        <Card.Description>
                            Show flexi desks and their real time status if available.
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <ReservableStatusColors />
                    </Card.Content>
                </Card>

                <Card onClick={() => {
                    dispatch(infoLayerVisibility());
                }}>
                    <Card.Content>
                        <Icon name='map signs' size='big' style={iconStyle} color='blue' />
                        <Card.Header>Info</Card.Header>
                        <Card.Meta>Markers and labels</Card.Meta>
                        <Card.Description>
                            Information about the working environment
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content>
                        <Icon name='thermometer' size='big' style={iconStyle} color='blue' />
                        <Card.Header>Air Quality</Card.Header>
                        <Card.Meta>Sensor information</Card.Meta>
                        <Card.Description>
                            <AirQualityLayerLabel />
                        </Card.Description>
                    </Card.Content>
                </Card>

            </Card.Group>
            </div>
        </div>
    );
}


export function BottomMenu() {

    const [date, setDate] = useState('');
    const dispatch = useDispatch();
    const mainMenu = useSelector(state => state.mainMenu);

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(moment()
                .format('dddd, MMMM Do YYYY HH:mm:ss')
                .toString());
        }, 1000)
        return () => clearInterval(interval)
    }, []);

    const menuStyle = {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        padding: 20,
    };

    const clockStyle = {
        position: "absolute",
        top: 15,
        right: 20,
        fontSize: 10,
        display: isSmallScreen() ? "none" : "inline"
    };

    const showHideIconDiv = {
        overflow: "hidden",
        textAlign: "center"
    };

    const showHideIconSpan = {
        color: "#fff",
        display: "inline-block",
        margin: "0 0 0 0",
        padding: "0px 0px"
    }
    const titleLabelStyle = {
        position: "absolute",
        left: "20px"
    };


    return (
        <div>
            <div style={menuStyle} className='unselectable'>
                <div style={showHideIconDiv}>
                    <span style={titleLabelStyle}>
                        <Header as='h5'>
                            <Icon name='sliders horizontal' />
                            <Header.Content>{isSmallScreen() ? 'Layers' : 'Ubikampus Map Layers'}</Header.Content>
                        </Header>
                    </span>
                    <span style={showHideIconSpan} onClick={() => {                        
                        if(mainMenu.open) {
                            dispatch(closeMainMenu());
                        } else {
                            dispatch(showMainMenu());
                        }
                    }}>
                        <Icon size='big' name={mainMenu.open ? "angle down" : "angle up"} />
                    </span>
                    <span style={clockStyle}><h5>{date}</h5></span>
                </div>
                <UbiMapLayerCardsGroups />
            </div>
        </div>
    )

}