import React, { Component } from 'react';
import './App.css';


import store from './store'
import { Provider } from 'react-redux'

import MainUI from './components/MainUI'

import * as serviceWorker from './serviceWorker';

import UbiMapService from './services/ubimapService';
const ubiMapService = new UbiMapService(store, "uri");


class App extends Component {
  
  render() {
    return (
      <Provider store={store}>
        <MainUI />
      </Provider>
    );
  }
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


export default App;
