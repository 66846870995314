import {Room, Desk} from "./Reservable/Room";

export class RoomsBlock {
  constructor(position) {

    // top–left coner position of the first room
    this.position = position;
    this.lastAddedRoom = null;
    this.wallThickness = 2;
    this.rooms = [];
    this.width = 0;
  }

  initializeWithDefinitions(container, roomsBlockDefinition) {
    
    const rooms = roomsBlockDefinition.rooms;
    
    for (let index = 0; index < rooms.length; index++) {
      const roomDetails = rooms[index];
      if(roomDetails.type === 'room') {
        const dimensions = roomDetails.dimensions;
        dimensions.height = roomsBlockDefinition.height;
        const room = new Room(container, roomDetails.id, roomDetails.parent, roomDetails.details, dimensions, roomDetails.enabled);
        this.addRoom(room);
        room.draw();
      }
    }
  }

  addRoom(room) {
    if(this.lastAddedRoom){
      
      room.setPosition({
        x: this.lastAddedRoom.position.x + this.lastAddedRoom.reservableWidth + this.wallThickness,
        y: this.lastAddedRoom.position.y
      });
      
    } else {
      room.setPosition(this.position);
    }
    this.rooms.push(room);
    this.lastAddedRoom = room;
    this.reservableWidth += this.lastAddedRoom.reservableWidth + this.wallThickness;
  }
}

export class VerticalBlock {
  constructor(position) {

    // top–left coner position of the first room
    this.position = position;
    this.lastAddedDrawable = null;
    this.distanceToNext = 5;
    this.drawables = [];
    this.blockWidth = 0;
  }

  initializeWithDefinitions(container, drawableDefinitions) {
    
    const desks = drawableDefinitions.desks;

    for (let index = 0; index < desks.length; index++) {

      
      const drawableDetails = desks[index];
      if(drawableDetails.type === 'Desk') {
        let dimensions = {
          width: drawableDefinitions.deskHeight,
          height: drawableDefinitions.deskWidth
        };

        if(drawableDetails.enabled) {
          const drawable = new Desk(container, drawableDetails.id, drawableDetails.parent, drawableDetails, dimensions, drawableDetails.enabled);
          this.addDrawable(drawable);
          drawable.draw();        
        }
      }
    }
  }

  addDrawable(drawable) {
    if(this.lastAddedDrawable){
      
      drawable.setPosition({
        x: this.lastAddedDrawable.position.x,
        y: this.lastAddedDrawable.position.y + this.lastAddedDrawable.reservableHeight + this.distanceToNext
      });
      
    } else {
      drawable.setPosition(this.position);
    }
    this.drawables.push(drawable);
    this.lastAddedDrawable = drawable;
    this.reservableWidth += this.lastAddedDrawable.reservableWidth + this.distanceToNext;
  }
}

