import watch from 'redux-watch';
import { showRoomInfoModal } from './../../../actions';

import { Statuses } from '../../Map/Reservable/Room';


export default function createRoomMediatorWithReduxWatch({ store, view }) {
    const { dispatch, subscribe } = store;
    // set default value    

    if(view.type && view.type === 'room') {
        view.setStatusFree();
    }
    
    const w1 = watch(store.getState, 'roomsStatusReducer.' + view.identity + '.reservationStatus');
    let unsubscribe1 = store.subscribe(w1((newVal, oldVal, objectPath) => {
        //console.log('CHANGED: roomsStatusReducer reservationStatus', newVal);
        view.setStatus(newVal);
    }));

    const w2 = watch(store.getState, 'roomsStatusReducer.' + view.identity + '.occupancyStatus');
    let unsubscribe2 = store.subscribe(w2((newVal, oldVal, objectPath) => {
        
        if(view.type && view.type === 'desk') {
            if(newVal === Statuses.NOT_OCCUPIED) {
                view.setStatusFree();
            } 
        } 

        //console.log('CHANGED: roomsStatusReducer occupancyStatus', newVal);
        view.setStatus(newVal);
    }));


    // dispatch action 
    const onClick = () => {
        console.log('Clicked', view.identity);
        
        dispatch(showRoomInfoModal(view.identity));
    }
    view.on('click', onClick);
    view.on('pointertap', onClick);

    // clear listeners
    return () => {
        view.off('click', onClick);
        unsubscribe1();
        unsubscribe2();
    }
}