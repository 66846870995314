import React from 'react';
import { Button, Header, Image, Modal, List, Label, Icon, Statistic, Divider } from 'semantic-ui-react'

import { useSelector, useDispatch } from 'react-redux';
import { Statuses } from './Map/Reservable/Room';
import { showRoomInfoModal, closeRoomInfoModal } from '../actions';

import useImage from '../misc/useImage';
import meetingRoomImage from '../images/meetingroom.jpg';


import { WorldModel } from '../world/ubikampus';

function loadRoomsInfo() {
    const rooms = {};

    if (WorldModel && WorldModel.model) {
        for (let index = 0; index < WorldModel.model.length; index++) {
            const element = WorldModel.model[index];
            if ((element.type === 'room-block-horizontal' || element.type === 'room-block-vertical') && element.rooms) {
                for (let j = 0; j < element.rooms.length; j++) {
                    const roomInfo = element.rooms[j];
                    rooms[roomInfo.id] = roomInfo;
                }
            }

            if (element.type === 'room') {
                rooms[element.id] = element;
            }
        }
    }
    return rooms;
}


const roomsInfo = loadRoomsInfo();

export function RoomInfoModal() {

    const dispatch = useDispatch();

    const roomInfoForModal = useSelector(state => state.roomInfoForModal);

    const selectedRoomId = roomInfoForModal.roomId;
    const roomInfo = roomsInfo && roomsInfo[selectedRoomId] ? roomsInfo[selectedRoomId] : {};

    const roomStatus = useSelector(state => state.roomsStatusReducer);


    let header = '';
    let keyline = '';

    let roomImage = roomInfo && roomInfo.id ? roomInfo.id : 'meetingroom';
    const { loading, error, image } = useImage(roomImage);

    if (roomInfo.details) {

        roomImage = error ? meetingRoomImage : image;

        console.log('ROOM iNFO', roomInfo);


        header = roomInfo.details.name;
        keyline = roomInfo.details.title;


        let equipment;
        if (roomInfo.details.equipment) {
            let equipmentList = roomInfo.details.equipment.map((item) => {
                let icon;
                switch (item) {
                    case 'TV / monitor':
                        icon = 'tv';
                        break;
                    case 'Web camera':
                        icon = 'video camera';
                        break;
                    case 'Jabra':
                        icon = 'phone volume';
                        break;
                    case 'Coffee machine':
                        icon = 'coffee';
                        break;
                    case 'Electric kettle':
                        icon = 'coffee';
                        break;
                    case 'Fridge':
                        icon = 'snowflake outline';
                        break;
                    case 'Microwave oven':
                        icon = 'food';
                        break;
                    default:
                        icon = 'setting';
                        break;
                }

                return <List.Item key={item}>
                    <List.Icon name={icon} />
                    <List.Content>{item}</List.Content>
                </List.Item>
            });
            equipment = <div>
                <br />
                <p>
                    This room is equipped with:
                </p>
                <List>
                    {equipmentList}
                </List>
            </div>;
        }

        let email;
        if (roomInfo.details.email) {
            email = <div>
                <List>
                    <List.Item
                        icon='calendar check'
                        content={<p>Reservations via ad hoc meeting tablets or with calendar invites: <a href='#'>{roomInfo.details.email}</a></p>}
                    />
                </List>
            </div>;
        }

        let description;
        if (roomInfo.details.description) {
            description = <div>
                <p>{roomInfo.details.description}</p>
            </div>;
        }


        let roomStatusOccupied;
        let roomStatusReserved;
        let roomStatusFree;
        let occupancyLevel;
        if (roomInfo.id && roomStatus[roomInfo.id]) {

            if (roomStatus[roomInfo.id].roomOccupancyLevel) {
                occupancyLevel = <center><Statistic>
                    <Header>Utility Level</Header>
                    <Statistic.Value>{roomStatus[roomInfo.id].roomOccupancyLevel}</Statistic.Value>
                    <Statistic.Label>Occupancy minutes / last 24h</Statistic.Label>
                </Statistic></center>
            }


            const statusLabelStyle = {
                float: "right",
                marginLeft: "5px"
            };

            const statusIconStyle = {
                color: "white"
            };

            console.log('roomStatus[roomInfo.id].occupancyStatus', roomStatus[roomInfo.id].occupancyStatus);

            if (roomStatus[roomInfo.id].occupancyStatus === Statuses.OCCUPIED) {
                roomStatusOccupied = <Label as='a' style={statusLabelStyle} color='red'>
                    <Icon name='users' style={statusIconStyle} /> Occupied
                </Label>
            }
            if (roomInfo.details.roomType === 'meeting' || roomInfo.details.roomType === 'booth') {
                if (roomStatus[roomInfo.id].reservationStatus === Statuses.RESERVED) {
                    roomStatusReserved = <Label as='a' style={statusLabelStyle} color='yellow'>
                        <Icon name='calendar' style={statusIconStyle} /> Reserved
                    </Label>
                }
            }
            
            if (!roomStatusOccupied && !roomStatusReserved) {
                roomStatusFree = <Label as='a' style={statusLabelStyle} color='green'>
                    <Icon name='calendar' style={statusIconStyle} /> Free
                </Label>
            }

        }



        let open = roomInfo.open;
        let clicked = false;
        function hack() {
            if (!clicked) {
                clicked = true;
                open = true;
            } else {
                dispatch(closeRoomInfoModal());
            }
        }


        const inlineStyle = {
            modal: {
                marginTop: '0px !important',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        };

        return (

            <Modal
                style={inlineStyle}
                className='infoModal'
                closeIcon
                open={roomInfoForModal.open}
                onClose={hack}
                closeOnDocumentClick={true}
                closeOnDimmerClick={true}
            >

                <Modal.Header>{header}
                    {roomStatusOccupied}{roomStatusReserved}{roomStatusFree}
                </Modal.Header>
                <Modal.Content image>
                    <Image size='medium' src={roomImage} wrapped />
                    <Modal.Description>
                        <Header>{keyline}</Header>
                        {description}
                        {email}
                        {equipment}
                        <Divider />
                        <br />
                        {occupancyLevel}
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => {
                        dispatch(closeRoomInfoModal())
                    }} primary>
                        Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    } else {
        return <div />
    }
};
