import DOOR_TEXTURE from '../../images/Door.png';
import * as PIXI from "pixi.js";

function flip(sprite) {
    sprite.anchor.y = 1;     /* 0 = top, 0.5 = center, 1 = bottom */
    sprite.scale.y *= -1;
    sprite.anchor.y = 0;          
  }
  

export class Door extends PIXI.Sprite {
    constructor(doorDetails) {

        const door = new PIXI.Sprite.from(DOOR_TEXTURE);
        door.position = {
          x: doorDetails.position.x,
          y: doorDetails.position.y
        };
        
        if(doorDetails.side === 'left') {
          door.angle = 180;
          flip(door);
        } 
        return door;
    }
}