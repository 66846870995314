

// Colors
export const Colors = {
    ROOM_LINE: "0xffffff",
    ROOM_NAME: "0xffffff",
    ROOM_BASE: "0xDFDFDF",
    ROOM_STATUS_OCCIPIED: "0xC56B6B",
    ROOM_STATUS_RESERVED: "0xE9AA43",
    ROOM_STATUS_FREE: "0xA4C7A0",
    AREA_STAFF: "0xB2A8BF",
    AREA_STUDENT: "0xAAB6B1"
};

export function getHEXString(colorHex) {
    return colorHex.replace('0x', '#');
}
