
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { showMainInfoModal } from './../../actions';

import { Button, Icon, Transition } from 'semantic-ui-react';

export function InfoBtn(props) {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
      const interval = setInterval(() => {
          setVisible(!visible);
      }, 5000)
      return () => clearInterval(interval)
  }, [visible]);

  const buttonStyle = {
    position: "fixed",
    top: (window.innerWidth < 400 ? 10 : 10),
    right: 10
  };

  return (
    <div style={buttonStyle}>
      <Transition
            animation='pulse'
            duration='1200'
            visible={visible}
          >
        <Icon circular color='blue' inverted size='big' name='info' onClick={() => { 
          dispatch(showMainInfoModal());
        }} />
        </Transition>
    </div>
  )
}