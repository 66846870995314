import { Icon, Card, List } from 'semantic-ui-react'

import BUILDING_FIG from '../../images/physicum.png';

function isSmallScreen() {
    return window.innerWidth < 500;
}


export const TopInfoMenu = () => {

    const extra = (
        <span><Icon name='bug' />Command {'jep'}</span>
    );

    const stateList = (
        <List>
            <List.Item>
                <List.Icon name='marker' color='blue' />
                <List.Content>
                    <List.Header>
                        <span style={{ position: "absolute", left: "32px" }}>X: {'jep'}</span><span style={{ width: "70px", position: "absolute", right: "2px" }}>Y: {'jep'}</span>
                    </List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name='battery three quarters' color='blue' />
                <List.Content>
                    <List.Header>
                        <span style={{ position: "absolute", left: "32px" }}>{'jep'}%</span><span style={{ width: "70px", position: "absolute", right: "2px" }}>{'jep'}V</span>
                    </List.Header>
                </List.Content>
            </List.Item>

            <List.Item>
                <List.Icon name='settings' color='blue' />
                <List.Content>
                    <List.Header>
                        <span style={{ position: "absolute", left: "32px" }}>Motors {'jep'}</span>
                    </List.Header>
                </List.Content>
            </List.Item>
        </List>
    );

    const buildingStateStyle = {
        position: "fixed",
        top: 10,
        left: 10,
        fontSize: 10,
        width: "150px",
        display: isSmallScreen() ? "none" : "inline"
    };


    return (
        <Card style={buildingStateStyle}
            image={BUILDING_FIG}
            header={'Ubikampus'}
            meta={'Physicum, 2nd Floor'}
            //description={stateList}
            //extra={extra}
        />);
};