import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

import { useSelector, useDispatch } from 'react-redux';
import { Statuses } from '../Map/Reservable/Room';
import { roomOccupancyStatus, roomReservationStatus, showRoomInfoModal } from './../../actions';

import SETTINGS from '../../settings.json';

export function MapModeBtn(props) {
  const dispatch = useDispatch();

  const mapModeStyle = {
    position: "fixed",
    top: (window.innerWidth < 400 ? 10 : 10),
    right: 250,
    width: "85px",
    fontSize: 10
  };

  if (SETTINGS.DEVELOPMENT) {

    return (
      <div>
        <Label style={mapModeStyle} onClick={() => {
          console.log('Map Mode Button Pressed');
          //dispatch(roomOccupancyStatus('java', Statuses.OCCUPIED));
          dispatch(showRoomInfoModal('java'));
        }}>
          <Icon name='clone outline' color='blue' /><span>Map mode</span>
        </Label>
      </div>
    )
  } else {
    return <div />
  }
}