import * as types from './../actions/actionTypes';

export function clickedCount(state = 0, action) {
  switch (action.type) {
    case types.ADD:
      return ++state;
    case types.RESET:
      return 0;
    default:
      return state;
  }
}


export function roomsStatus(state = {}, action) {
  switch (action.type) {
    case types.ROOM_OCCUPANCY_STATUS:

      const newState1 = {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          occupancyStatus: action.payload.occupancyStatus
        }
      };
      return newState1;

    case types.ROOM_RESERVATION_STATUS:
      //console.log('PAYLOAD', action.payload);

      const newState2 = {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          reservationStatus: action.payload.reservationStatus
        }
      };

      return newState2;


    case types.ROOM_OCCUPANCY_LEVEL:
      const newState3 = {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          roomOccupancyLevel: action.payload.roomOccupancyLevel
        }
      };

      return newState3;

    case types.ROOM_RESERVATION_LEVEL:
      const newState4 = {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          roomReservationLevel: action.payload.roomReservationLevel
        }
      };

      return newState4;

    default:
      return state;
  }
}





export function layerVisibility(state = {
  room: true,
  iot: true,
  info: true
}, action) {
  switch (action.type) {
    case types.ROOM_LAYER_VISIBILITY:
      return {
        ...state,
        room: !state.room
      };
    case types.IOT_LAYER_VISIBILITY:
      return {
        ...state,
        iot: !state.iot
      };
    case types.INFO_LAYER_VISIBILITY:
      return {
        ...state,
        info: !state.info
      };
    default:
      return state;
  }
}

export function mousePosition(state = { x: 0, y: 0 }, action) {
  switch (action.type) {
    case types.MOUSE_POSITION:
      return {
        x: action.payload.x | 0,
        y: action.payload.y | 0
      };

    default:
      return state;
  }
}

export function roomInfoForModal(state = {
  open: false
},
  action) {

  switch (action.type) {
    case types.SHOW_ROOM_INFO_MODAL:
      return { open: true, roomId: action.payload.roomId };
    case types.CLOSE_ROOM_INFO_MODAL:
      return { open: false, roomId: undefined };
    default:
      return state;
  }
}


export function mainInfoForModal(state = {
  open: false
},
  action) {

  switch (action.type) {
    case types.SHOW_MAIN_INFO_MODAL:
      return { open: true };
    case types.CLOSE_MAIN_INFO_MODAL:
      return { open: false };
    default:
      return state;
  }
}

export function mainMenu(state = {
  open: true
},
  action) {

  switch (action.type) {
    case types.SHOW_MAIN_MENU:
      return { open: true };
    case types.CLOSE_MAIN_MENU:
      return { open: false };
    default:
      return state;
  }
}

export function sensorStatus(state = {}, action) {
  switch (action.type) {
    case types.SENSOR_STATUS:
      return {
        ...state,
        [action.payload.id]: { status: action.payload.status }
      };

    default:
      return state;
  }
}

export function sensorSelection(state = {
  selectedSensor: 'voc'
}, action) {
  switch (action.type) {
    case types.SENSOR_SELECTION:
      console.log('selectedSensor: action.payload', action.payload);

      return {
        ...state,
        selectedSensor: action.payload
      };

    default:
      return state;
  }
}