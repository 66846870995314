import axios from 'axios'
import io from 'socket.io-client';

import { Statuses } from '../components/Map/Reservable/Room';
import {
  roomOccupancyStatus,
  roomReservationStatus,
  roomOccupancyLevel,
  roomReservationLevel,
  sensorStatus
} from '../actions';


import SETTINGS from '../settings';

let baseUrl = '';


class UbiMapService {
  constructor(store, uri) {

    const { dispatch } = store;

    const context = this;

    baseUrl = SETTINGS.UBIMAP_SERVICE_URI;
    console.log('Data URI', baseUrl);

    const socket = io(baseUrl, { secure: false });

    socket.on('connect', function () {
      console.log('Connected to UbiMap Service');
      // TODO dispatch and visualize that backend is currently connected
    });

    socket.on('test', function (params) {
      console.log('Connected -> Getting init data');
      context.getInitData(socket);
    });

    socket.on('sensor_status', function (params) {
      console.log('Sensor data');

      if (params.type === 'airquality') {
        dispatch(sensorStatus(params.id, params));
      }
    });

    socket.on('desk_status', function (params) {
      

      if (params.type === 'desk_occupancy') {
        
        if (params.used === true || params.used === 1) {

          dispatch(roomOccupancyStatus(params.name, Statuses.OCCUPIED));

        } else if (params.used === false || params.used === 0) {

          dispatch(roomOccupancyStatus(params.name, Statuses.NOT_OCCUPIED));
        } else {
          
        }

        //if(params.occupancyLevel) {
        //  dispatch(roomOccupancyLevel(params.name, params.occupancyLevel));
        //}
      }


    });


    socket.on('room_status', function (params) {

      if (params.type === 'occupancy') {

        if (params.used === true || params.used === 1) {
          dispatch(roomOccupancyStatus(params.name, Statuses.OCCUPIED));

        } else if (params.used === false || params.used === 0) {
          dispatch(roomOccupancyStatus(params.name, Statuses.NOT_OCCUPIED));
        }

        if (params.occupancyLevel) {
          dispatch(roomOccupancyLevel(params.name, params.occupancyLevel));
        }


      } else if (params.type === 'reservation') {

        if (params.subtype === 'starting') {
          dispatch(roomReservationStatus(params.name, Statuses.RESERVED));

        } else if (params.subtype === 'ending') {
          dispatch(roomReservationStatus(params.name, Statuses.NOT_RESERVED));
        }
      }
    });

    socket.on('rooms_statuses', function (params) {

      function initializeWithRoomStatuses(roomStatuses) {

        roomStatuses.forEach(roomStatusParams => {
          try {
            //console.log('roomStatusParams', roomStatusParams);

            if (roomStatusParams.occupied.isOccupied) {
              dispatch(roomOccupancyStatus(roomStatusParams.reservableId, Statuses.OCCUPIED));
            } else {
              dispatch(roomOccupancyStatus(roomStatusParams.reservableId, Statuses.NOT_OCCUPIED));
            }

            if (roomStatusParams.reserved && roomStatusParams.reserved.isReserved) {
              dispatch(roomReservationStatus(roomStatusParams.reservableId, Statuses.RESERVED));
            } else {
              dispatch(roomReservationStatus(roomStatusParams.reservableId, Statuses.NOT_RESERVED));
            }

            if (roomStatusParams.occupancyLevel) {
              dispatch(roomOccupancyLevel(roomStatusParams.reservableId, roomStatusParams.occupancyLevel));
            }


          } catch (error) {
            console.log('Error', error);
          }
        });
      }

      initializeWithRoomStatuses(params);

    });



    socket.on('desks_statuses', function (params) {

      function initializeWithDeskStatuses(deskStatuses) {

        deskStatuses.forEach(deskStatusParams => {
          try {

            if (deskStatusParams.occupied.isOccupied) {

              dispatch(roomOccupancyStatus(deskStatusParams.reservableId, Statuses.OCCUPIED));
            } else {

              dispatch(roomOccupancyStatus(deskStatusParams.reservableId, Statuses.NOT_OCCUPIED));
            }

            if (deskStatusParams.reserved && deskStatusParams.reserved.isReserved) {
              dispatch(roomReservationStatus(deskStatusParams.reservableId, Statuses.RESERVED));
            } else {
              dispatch(roomReservationStatus(deskStatusParams.reservableId, Statuses.NOT_RESERVED));
            }

            if (deskStatusParams.occupancyLevel) {
              dispatch(roomOccupancyLevel(deskStatusParams.reservableId, deskStatusParams.occupancyLevel));
            }


          } catch (error) {
            console.log('Error', error);
          }
        });
      }

      initializeWithDeskStatuses(params);

    });



    socket.on('disconnect', function () {
      console.log('Disconnected from Ubi Map service');
      //props.middlewareStatus('map', false);
    });

    // Format: map_page_id: timestamp
    //this.mapPagesFetched = {};
  }


  getInitData = (socket) => {
    try {
      socket.emit('getReservableStatuses');
    } catch (error) {
      console.error(error);
      return;
    }
  }



  getReservations = () => {
    try {

      const testURI = "https://iot.ubikampus.net:9004/calendar/physicum.g247@ad.helsinki.fi/reservations?today=true&token=eyeJ00";
      /*
            async function getOptions() {
              const options = {
                headers: {
                  Authorization: `Bearer ${await getAuthToken()}`
                }
              };
              return options;
            }
      */

      const request = axios.get(testURI, { crossDomain: true });
      return request.then((response) => {

        // TODO: Handle init data in future..
        const initData = response.data.reservations ? response.data.reservations : [];
        console.log('initData', initData);
        //this.props.initializeMapData(initData);
        /*
                // Handle map page init data
                const mapPages = response.data.mapPages ? response.data.mapPages : [];
                for (let i = 0; i < mapPages.length; i++) {
                  let mapPageData = mapPages[i];
                  //this.props.mapPageChanged(baseUrl, mapPageData);
                }
        */
        return;

      }, (error) => {
        if (error.response && error.response.status === 404) {
          console.log('Error', error);
          return [];
        }

      }).catch((er) => {
        console.error(er);
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}


export default UbiMapService;







/*
const getAllFetch = () => {
console.log('bbb');
  var that = this;
  var url = baseUrl + 'ds'

  fetch(url)
  .then(function(response) {
    if (response.status >= 400) {
      console.log('error');
      throw new Error("Bad response from server");
    }
    return response.json();
  })
  .then(function(data) {
    console.log(data);
  })
  .catch(function(error) {
    console.log('eee', error);
    return [];
  });
}
*/

/*export default {
  getAll, getSingle, getSingleAsync
};*/




