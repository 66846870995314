import watch from 'redux-watch';

export default function createSensorMediatorWithReduxWatch({ store, view }) {
    const { dispatch, subscribe } = store;

    const defaultStatus = store.getState().sensorStatus;


    // redux watch and update view after redux store state update.
    const w = watch(store.getState, 'sensorStatus.' + view.identity);
    let unsubscribe = store.subscribe(w((newVal, oldVal, objectPath) => {
        view.setStatus(newVal.status);
    }))

    const defaultSelectedSEnsor = store.getState().sensorSelection;
    const w2 = watch(store.getState, 'sensorSelection');
    let unsubscribe2 = store.subscribe(w2((newVal, oldVal, objectPath) => {
        console.log('newVal.selectedSensor', newVal);
        view.setSensorSelection(newVal.selectedSensor);
    }))


    // dispatch action 
    const onClick = () => {
        console.log('TODO: Show sensor info');
        //dispatch(showRoomInfoModal(view.name, {}));
    }
    //view.on('click', onClick);
    //view.on('pointertap', onClick);


    // clear listeners
    return () => {
        //view.off('click', onClick);
        unsubscribe();
    }
}